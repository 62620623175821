@font-face {
  font-family: 'adelia';
  src: url(./Assets/Fonts/adelia/adelia.woff2) format('woff2'),
      url(./Assets/Fonts/adelia/adelia.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Blackout';
  src: url(./Assets/Fonts/blackOut/BlackoutRegular.woff2) format('woff2'),
      url(./Assets/Fonts/blackOut/BlackoutRegular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url(./Assets/Fonts/bebasNeu/BebasNeueBold.woff2) format('woff2'),
      url(./Assets/Fonts/bebasNeu/BebasNeueBold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Berkshire Swash';
  src: url(./Assets/Fonts/berkshireSwash/BerkshireSwash-Regular.woff2) format('woff2'),
      url(./Assets/Fonts/berkshireSwash/BerkshireSwash-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'COURGETTE-REGULAR';
  src: url(./Assets/Fonts/courgette/COURGETTE-REGULAR.woff2) format('woff2'),
      url(./Assets/Fonts/courgette/COURGETTE-REGULAR.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AirstrikeBold3D';
  src: url(./Assets/Fonts/airstrike/AirstrikeBold3D.woff2) format('woff2'),
      url(./Assets/Fonts/airstrike/AirstrikeBold3D.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KrutiDev';
  src: url(./Assets/Fonts/KrutiDev/KRDEV010.woff2) format('woff2'),
      url(./Assets/Fonts/KrutiDev/KRDEV010.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'franklingothic';
  src: url(./Assets/Fonts/franklingothic/Franklin-Gothic.woff2) format('woff2'),
      url(./Assets/Fonts/franklingothic/Franklin-Gothic-Demi-Cond-Regular.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Android Assassin';
  src: url(./Assets/Fonts/AndroidAssassin/Android-Assassin.woff2) format('woff2'),
  url(./Assets/Fonts/AndroidAssassin/Android-Assassin.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lucida Handwriting';
  src: url(./Assets/Fonts/LucidaHandwriting/Lucida-Handwriting-Italic.woff2) format('woff2'),
  url(./Assets/Fonts/LucidaHandwriting/Lucida-Handwriting-Italic.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MurdenCFExtraBold';
  src: url(./Assets/Fonts/MurdenCFExtraBold/MurdenCFExtraBold-Regular.woff2) format('woff2'),
  url(./Assets/Fonts/MurdenCFExtraBold/MurdenCFExtraBold-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Shakies';
  src: url(./Assets/Fonts/Shakies/Shakies.woff2) format('woff2'),
  url(./Assets/Fonts/Shakies/Shakies.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bernard';
  src: url(./Assets/Fonts/bernard/BERNHC.woff2) format('woff2'),
  url(./Assets/Fonts/bernard/BERNHC.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Castellar Regular';
  src: url(./Assets/Fonts/castelarRegular/CASTELAR.woff2) format('woff2'),
  url(./Assets/Fonts/castelarRegular/CASTELAR.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body{
  font-family: 'Poppins', sans-serif !important;
  background-color:#ffffff !important;
}
body.sidebar-folded .sidebarFolded{
  margin-left: 105px;
}
.canvas-open{
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  body.sidebar-folded .sidebarFolded{
    margin-left: 10px;
  } 
}