@font-face {
  font-family: 'adelia';
  src: url(/static/media/adelia.1edb8c19.woff2) format('woff2'),
      url(/static/media/adelia.87ddadfc.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Blackout';
  src: url(/static/media/BlackoutRegular.f41f6950.woff2) format('woff2'),
      url(/static/media/BlackoutRegular.caa7b820.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url(/static/media/BebasNeueBold.73506c13.woff2) format('woff2'),
      url(/static/media/BebasNeueBold.6e9adec4.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Berkshire Swash';
  src: url(/static/media/BerkshireSwash-Regular.5ff62aea.woff2) format('woff2'),
      url(/static/media/BerkshireSwash-Regular.d52f53ee.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'COURGETTE-REGULAR';
  src: url(/static/media/COURGETTE-REGULAR.39380392.woff2) format('woff2'),
      url(/static/media/COURGETTE-REGULAR.95e72bfd.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AirstrikeBold3D';
  src: url(/static/media/AirstrikeBold3D.c8fc20be.woff2) format('woff2'),
      url(/static/media/AirstrikeBold3D.57f2c545.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KrutiDev';
  src: url(/static/media/KRDEV010.85632626.woff2) format('woff2'),
      url(/static/media/KRDEV010.f4f7d917.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'franklingothic';
  src: url(/static/media/Franklin-Gothic.e986c7f1.woff2) format('woff2'),
      url(/static/media/Franklin-Gothic-Demi-Cond-Regular.f2b02267.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Android Assassin';
  src: url(/static/media/Android-Assassin.fb3848e4.woff2) format('woff2'),
  url(/static/media/Android-Assassin.7f5ca930.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lucida Handwriting';
  src: url(/static/media/Lucida-Handwriting-Italic.01b6cb24.woff2) format('woff2'),
  url(/static/media/Lucida-Handwriting-Italic.ec811937.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MurdenCFExtraBold';
  src: url(/static/media/MurdenCFExtraBold-Regular.adf4709f.woff2) format('woff2'),
  url(/static/media/MurdenCFExtraBold-Regular.8409f339.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Shakies';
  src: url(/static/media/Shakies.36b15a30.woff2) format('woff2'),
  url(/static/media/Shakies.baa5b769.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bernard';
  src: url(/static/media/BERNHC.5f328a90.woff2) format('woff2'),
  url(/static/media/BERNHC.b5c7c743.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Castellar Regular';
  src: url(/static/media/CASTELAR.5b950c3f.woff2) format('woff2'),
  url(/static/media/CASTELAR.af18cfa2.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body{
  font-family: 'Poppins', sans-serif !important;
  background-color:#ffffff !important;
}
body.sidebar-folded .sidebarFolded{
  margin-left: 105px;
}
.canvas-open{
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  body.sidebar-folded .sidebarFolded{
    margin-left: 10px;
  } 
}
